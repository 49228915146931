*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height:auto;
    background-color: transparent;
}

.navbar {
    display: flex;
  justify-content: center; /* Centers the entire nav content */
  align-items: center; /* Vertically aligns the content */
  background-color: black; /* Dark background color as in the image */
  height: auto;
  width: 100%; 
    
}

.logo{
    font-size:35px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: black;
}

.nav-links {
    display: contents;
    justify-content: center;
    align-items: center;
    list-style: none;
    width: 80%;
    background-color: black;
    font-family: 'Poppins', sans-serif;
}

.school_corporate,
.medical_student,
.doctor,
.hospital,
.login{
    text-decoration: none;
    font-size:25px;
    font-weight: 200;
    padding: 10px 20px;
    margin: 0 5%;
    cursor: pointer;
    background-color: black;
    font-family: 'Poppins', sans-serif;
    color:white;
}

.login{
    border: 1px solid rgb(253, 253, 254);
    border-radius: 5px;
}

.mobile-menu-icon{
    display: none;
}


@media screen and (max-width: 780px) {
    .logo{
        display: none;
        position: fixed;
        left:5%;
    }

    

    .navbar{
        position: absolute;
    }
    .nav-links{
        display: none;
    }

    .nav-links-mobile{
        position: relative;
        text-align: left;
        overflow-x: hidden;
        display: block;
        list-style: none;
        background-color: black;
        left:0;
        top: 2px;
        transition: all 0.5s ease-out;
        width: 100%;
    }

    .school_corporate,
    .medical_student,
    .doctor,
    .hospital,
    .login{
        background-color: black;
        display: block;
    text-align: left;
    margin-top: 5%;
    margin-bottom: 5%;
    height:35px;
    width: 100%;
    transition: all 0.5s ease;
    font-size: x-large;
    }

.login{
    border: none;
}

.mobile-menu-icon{
    display: block;
    position: absolute;
    font-size: 30px;
    color:purple;
    border: white;
    outline: none;
    top: 15px;
    right: 25px;
}
}

/*
color:powderblue
@media only screen and (max-width: 734px) {
.header{
position: absolute;
}
  .headerName {
    position: relative;
left: 5%
  }
}*/